import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":\"400\",\"variable\":\"--font-roboto\",\"subsets\":[\"latin\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto_Slab\",\"arguments\":[{\"variable\":\"--font-roboto-slab\",\"subsets\":[\"latin\"]}],\"variableName\":\"robotoSlab\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-roboto-mono\",\"display\":\"swap\"}],\"variableName\":\"robotoMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Breadcrumbs"] */ "/app/src/components/Breadcrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SRE"] */ "/app/src/components/SRE.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/src/components/ThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ThemeToggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/app/src/components/WebVitals.tsx");
